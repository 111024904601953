import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDTypography from "components/MDTypography";

import Buyandsell from "./buyandsell";
//import { getZportifolio, getportifolio, Addwatchlist } from "../../../services/portifolioapis";
import { IconButton, Tooltip } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import { bookMarkStatus } from "services/transactionApis";
import { Addwatchlist, removeFromWatchlist } from "../../../services/portifolioapis";
import { toast } from "react-toastify";
import colors from "assets/theme/base/colors";
import { useMaterialUIController } from "context";
import { formatAndMultiplyValueAssetItem } from "utils/formatAndMultiplyValue";
import showToast from "utils/toastUtils";

// Data

function graphdetailsbox({
  Name,
  price,
  instrumentId,
  quantity,
  getQuantity,
  instrumentType,
  Exchange,
  askValue,
  bidValue,
  isMCX,
  modifyOrderType,
  modifyType,
  modifyPrice,
  modifyQuantity,
  orderId,
  upperCircuit,
  lowerCircuit,
  selectedOption
}) {
  const [openModal, setOpenModal] = useState(false);
  const [apiqData, setQData] = useState({
    Quantities: "0",
  });
  const [added, isAdded] = useState(false);
  const { black, white } = colors;
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;

  const handleOpenModal = () => {
    setOpenModal(true);
  };


  const handleBookmark = async () => {
    try {
      const data = {
        instrumentId: parseInt(instrumentId),
        exchange: instrumentType,
      };
      if (added) {
        await removeFromWatchlist(data);
        isAdded(false);
        showToast("Removed from watchlist", "success");
      } else {
        await Addwatchlist(data);
        isAdded(true);
        showToast("Added to watchlist", "success");
      }
    } catch (error) {
      console.error("Error updating watchlist:", error);
      showToast("Error updating watchlist", "error");
    }
  };

  const fetchStockStatus = async (instrumentId, isMCX) => {
    try {
      // const cod = instrumentType === "NSE" ? "MCX" : instrumentType;
      const response = await bookMarkStatus(instrumentId, isMCX);
      isAdded(response);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchStockStatus(instrumentId, isMCX);
  }, []);

  return (
    <Grid item xs={12} lg={3}>
      <Card sx={{ height: "453px" }}>
        <Grid sx={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
          <Tooltip title="Add to Watchlist" placement="right-start">
            <IconButton
              onClick={handleBookmark}
              sx={{ padding: "5px", marginRight: "20px", marginTop: "40px" }}
            >
              {added ? (
                <BookmarkIcon sx={{ height: 30, width: 35, padding: "0"}}  color="success"/>
              ) : (
                <BookmarkBorderOutlinedIcon
                  sx={{
                    height: 30,
                    width: 35,
                    padding: "0",
                    strokeWidth: 1,
                    // color: darkMode ? white.main : black.main,
                  }}
                  color="success"
                />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingX: 4,
            paddingTop: 3,
            gap: "10px",
          }}
        >
          <MDTypography>Name:</MDTypography>
          <Tooltip title={Name} placement="top" arrow>
            <MDTypography
              sx={{
                textOverflow: "ellipsis", // Hide overflow with an ellipsis
                overflow: "hidden", // Necessary for ellipsis
                whiteSpace: "nowrap",
              }}
            >
              {Name}
            </MDTypography>
          </Tooltip>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingX: 4,
            paddingTop: 3,
          }}
        >
          <MDTypography>Price</MDTypography>
          <MDTypography>
            {price==="NaN"?0:formatAndMultiplyValueAssetItem(price, instrumentType)}
          </MDTypography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingX: 4,
            paddingTop: 3,
          }}
        >
          <MDTypography>Quantity</MDTypography>
          <MDTypography color={quantity < 0 ? "error" : "success"} sx={{ fontWeight: 600 }}>
            {Math.abs(quantity)}
          </MDTypography>
        </Grid>

        <Buyandsell
          Name={Name}
          setQData={setQData}
          price={price}
          quant={quantity}
          instrumentId={instrumentId}
          quantity={quantity}
          getQuantity={getQuantity}
          instrumentType={instrumentType}
          Exchange={Exchange}
          askPrice={askValue}
          bidPrice={bidValue}
          upperCircuit={upperCircuit}
          lowerCircuit={lowerCircuit}
          modifyOrderType={modifyOrderType}
          modifyPrice={modifyPrice}
          modifyType={modifyType}
          modifyQuantity={modifyQuantity}
          orderId={orderId}
          selectedOption={selectedOption}
        />
      </Card>
    </Grid>
  );
}

export default graphdetailsbox;
