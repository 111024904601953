import React, { useState, useEffect } from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { Grid, Card, FormHelperText } from "@mui/material";
import Modal from "@mui/material/Modal";
import { ToastContainer, toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

import { fetchWallet } from "../../../../services/profileapis";
import { depositApi } from "services/transactionApis";
import { formatAndMultiplyValueAssetItem } from "utils/formatAndMultiplyValue";
import showToast from "utils/toastUtils";

export default function PortfolioCard({ marketContext, exchange }) {
  const [openWithdrawModal, setOpenWithdrawModal] = useState(false);
  const [openDepositModal, setOpenDepositModal] = useState(false);
  const [depositAmount, setDepositAmount] = useState("");
  const [disbaleWallet, setDisableWallet] = useState(false);
  const [errors, setErrors] = useState("");
  const [shouldDisable, setShouldDisable] = useState(false);

  // console.log("exchange------------------", exchange);

  const getMarketData = marketContext.find((item) => item.id === exchange);

  //Exchange for Deposit and Withdraw
  const walletExchangeTitle = exchange;

  const handleOpenWithdrawModal = () => setOpenWithdrawModal(true);
  const handleCloseWithdrawModal = () => {
    setOpenWithdrawModal(false);
    setDepositAmount("");
    setErrors("");
  };

  const handleOpenDepositModal = () => setOpenDepositModal(true);
  const handleCloseDepositModal = () => {
    setOpenDepositModal(false);
    setErrors("");
    setDepositAmount(" ");
  };

  useEffect(() => {
    fetchWallets(exchange);
    const isActiveDisable = localStorage.getItem("isActive") === "true";
    setShouldDisable(isActiveDisable);
  }, [exchange]);

  // wallet config api
  const fetchWallets = async (exchange) => {
    try {
      const token = sessionStorage.getItem("token");
      const decode = jwtDecode(token);
      const userid = decode.UserID;

      const responseData = await fetchWallet(userid, exchange);
      const result = responseData;
      setDisableWallet(result);
    } catch (error) {
      console.error("Error fetching wallet configuration:", error);
    }
  };
  // console.log("shouldDisable:", shouldDisable);
  const performTransactionAPI = async (transactionType) => {
    const amount = parseInt(depositAmount);

    if (isNaN(amount) || amount <= 0 || parseFloat(depositAmount) < 0) {
      setErrors("Please enter an amount more than zero.");
      return;
    } else if (depositAmount.includes(".") || depositAmount.includes("-")) {
      setErrors("Please enter a valid amount.");
      return;
    }

    const token = sessionStorage.getItem("token");
    const decode = jwtDecode(token);
    const userid = decode.UserID;

    try {
      let data;
      if (transactionType === "deposite") {
        data = {
          user_id: userid,
          balance: amount,
          currency: exchange === "NSE" || exchange === "MCX" ? "INR" : "USD",
          oversee_id: userid,
          exchange: exchange,
        };
      } else if (transactionType === "withdraw") {
        data = {
          user_id: userid,
          balance: -amount, // Notice the negative sign to indicate withdrawal
          currency: exchange === "NSE" || exchange === "MCX" ? "INR" : "USD",
          oversee_id: userid,
          exchange: exchange,
        };
      }

      const response = await depositApi(data);

      if (response && transactionType === "deposite") {
        setErrors("");
        setDepositAmount("");
        handleCloseDepositModal();
        showToast("Deposit Successfully Requested", "success");
      } else if (response && transactionType === "withdraw") {
        setErrors("");
        setDepositAmount("");
        handleCloseWithdrawModal();
        showToast("Withdrawal Successfully Requested", "success");
      }
    } catch (error) {
      console.error(`${transactionType} API error response:`, error.message);
      handleCloseWithdrawModal();
      handleCloseDepositModal();
      showToast(error.message || "Transaction failed due to an error", "error");
    }
  };

  const icon =
    getMarketData && getMarketData.percentage >= 0 ? (
      <ArrowUpwardIcon
        sx={{ height: "22px", width: "15px", paddingBottom: "2px" }}
      />
    ) : (
      <ArrowDownwardIcon
        sx={{ height: "22px", width: "15px", paddingTop: "2px" }}
      />
    );
  const percentageClassName =
    getMarketData && getMarketData.percentage < 0 ? "negative" : "";

  return (
    <Card
      sx={{
        padding: "20px",
        // height: "130px",
        height: "auto",
        width: "100%",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Grid
        lg={12}
        md={12}
        sm={12}
        xl={12}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
          paddingRight: "15px",
          gap: "10px",
          justifyContent: "center",
        }}
      >
        <Grid>
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "8px",
              gap: "10px",
              padding: "2px",
            }}
          >
            <MDBox>
              <img
                src={getMarketData?.Image}
                alt={getMarketData?.id}
                style={{ height: "35px", width: "35px" }}
              />
            </MDBox>
            <MDBox>
              <MDTypography data-testid="exchange-title">{getMarketData?.label}</MDTypography>
            </MDBox>
          </MDBox>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <MDBox>
            <MDTypography
              sx={{
                paddingRight: "10px",
                color: getMarketData?.totalValue >= 0 ? "none" : "#FF6961",
                fontWeight: 420,
              }}
            >
              {formatAndMultiplyValueAssetItem(
                Math.abs(getMarketData?.totalValue) || 0,
                getMarketData?.label
              )}
            </MDTypography>
          </MDBox>
          <MDBox>
            <Card
              sx={{
                display: "flex",
                alignItems: "center",
                height: "30px",
                width: "80px",
                justifyContent: "center",
                backgroundColor: "#e6e6e6",
                borderRadius: "20px",
              }}
            >
              <MDTypography
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  fontSize: "15px",
                  gap: "1px",
                  fontWeight: 500,
                }}
                color={percentageClassName === "negative" ? "error" : "success"}
              >
                {icon}
                <span>
                  {Math.abs(getMarketData?.percentage || 0).toFixed(2)}%
                </span>
              </MDTypography>
            </Card>
          </MDBox>
        </Grid>
      </Grid>
      {shouldDisable && disbaleWallet && (
        <Grid container spacing={1} justifyContent="space-around">
          <Grid item xs={6}>
            <MDButton
              color="info"
              data-testid="deposit-modal-button"
              fullWidth
              sx={{
                backgroundColor: "#007bff", // Blue button for deposit
                "&:hover": {
                  backgroundColor: "#0056b3",
                },
              }}
              onClick={handleOpenDepositModal}
            >
              Deposit
            </MDButton>
          </Grid>
          <Grid item xs={6}>
            <MDButton
              color="error"
               data-testid="withdraw-modal-button"
              fullWidth
              sx={{
                backgroundColor: "#dc3545", // Red button for withdraw
                "&:hover": {
                  backgroundColor: "#c82333",
                },
              }}
              onClick={handleOpenWithdrawModal}
            >
              Withdraw
            </MDButton>
          </Grid>
        </Grid>
      )}
      <Modal
        open={openDepositModal}
        onClose={handleCloseDepositModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              // height: "250px",
              width: "310px",
              borderRadius: "20px",
              padding: "20px",
              justifyContent: "center",
            }}
          >
            <>
              <MDTypography
                sx={{
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: 500,
                }}
              >
                {`${walletExchangeTitle} Wallet Deposit`}
              </MDTypography>
              {/* Text input for Deposit */}
              <MDInput
                type="number"
                fullWidth
                placeholder="Enter Deposit Amont"
                value={depositAmount}
                onChange={(e) => setDepositAmount(e.target.value)}
                error={Boolean(errors)}
                autoComplete="off"
                sx={{
                  marginTop: "10px",
                }}
              />
              {errors && (
                <FormHelperText
                  sx={{
                    color: "#FF0000",
                    fontWeight: 520,
                    marginY: "3px",
                  }}
                >
                  {errors}
                </FormHelperText>
              )}
            </>
            {/* )} */}
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <MDButton onClick={handleCloseDepositModal} color="error">
                Cancel
              </MDButton>
              <MDButton
                color="success"
                onClick={() => performTransactionAPI("deposite")}
              >
                Deposit
              </MDButton>
            </MDBox>
          </Card>
        </MDBox>
      </Modal>
      <Modal
        open={openWithdrawModal}
        onClose={handleCloseWithdrawModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MDBox
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              //height: "250px",
              width: "310px",
              borderRadius: "20px",
              padding: "20px",
              //display: 'flex',
              // alignItems: 'center'
              justifyContent: "center",
            }}
          >
            <>
              <MDTypography
                sx={{ textAlign: "center", fontSize: "20px", fontWeight: 500 }}
              >
                {`${walletExchangeTitle} Wallet Withdraw`}
              </MDTypography>
              {/* Text input for Withdraw */}

              <MDInput
                placeholder="Enter Withdraw Amount"
                type="number"
                fullWidth
                value={depositAmount}
                onChange={(e) => setDepositAmount(e.target.value)}
                error={Boolean(errors)}
                autoComplete="off"
                sx={{
                  marginTop: "10px",
                }}
              />
              {errors && (
                <FormHelperText
                  sx={{
                    color: "#FF0000",
                    fontWeight: 520,
                    marginY: "3px",
                  }}
                >
                  {errors}
                </FormHelperText>
              )}
            </>
            {/* )} */}
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <MDButton color="error" onClick={handleCloseWithdrawModal}>
                Cancel
              </MDButton>
              <MDButton
                color="success"
                onClick={() => performTransactionAPI("withdraw")}
              >
                Withdraw
              </MDButton>
            </MDBox>
          </Card>
        </MDBox>
      </Modal>
      <ToastContainer />
    </Card>
  );
}
