export const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
        const dateOptions = {
            year: "2-digit",
            day: "2-digit",
            month: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
    };
    let formattedDate= new Intl.DateTimeFormat("en-IN", dateOptions).format(
        date);

    formattedDate = formattedDate.replace('am', 'AM').replace('pm', 'PM');

    return formattedDate;
  };