// Getting token from sessionStorage
import { jwtDecode } from "jwt-decode";
const fastApiUrl = process.env.REACT_APP_FAST_API_URL;
//const token = sessionStorage.getItem("token");

export const depositApi = async (data) => {
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  const token = sessionStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found in session storage");
  }
  myHeaders.append("Authorization", `Bearer ${token}`);

  const raw = JSON.stringify(data);

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(`${fastApiUrl}/applyBalance`, requestOptions);

    if (!response.ok) {
      const errorMessage = await response.json(); // Extract error message from response body
      throw new Error(errorMessage.detail || "Network response was not ok");
    }

    return await response.json();
  } catch (error) {
    console.error("There was a problem with the deposit request:", error);
    throw error;
  }
};

export const bookMarkStatus = async (instrumentId, exchange) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }
    const decode = jwtDecode(token);
    const id = decode.UserID;
    const response = await fetch(`${fastApiUrl}/watchlist/${id}/${instrumentId}/${exchange}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      console.error("HTTP Status error", response.status);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Not able to get stock status:", error);
    throw error;
  }
};
