import React, { useState, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { getWeeklyPortfolio } from "services/userApis";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";
import { DataGrid } from "@mui/x-data-grid";
import {
  Grid,
  useMediaQuery,
  useTheme as T,
  IconButton,
  Menu,
  MenuItem,
  Autocomplete,
  TextField,
} from "@mui/material";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import customParseFormat from "dayjs/plugin/customParseFormat";
import MDBox from "components/MDBox";
import colors from "assets/theme/base/colors";
import { useMaterialUIController } from "context";
import { weeklyOptions } from "../../utils/Objects";
import { format } from "date-fns";
import { formatAndMultiplyValueAssetItem } from "utils/formatAndMultiplyValue";

function weekly() {
  dayjs.extend(customParseFormat);
  const [weeklyData, setWeeklyData] = useState([]);
  const [page, setPage] = useState(4);
  const [loading, setLoading] = useState(false);
  const t = T();
  const isMedium = useMediaQuery(t.breakpoints.down("xl"));
  const today = dayjs();
  const [start, setStart] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [end, setEnd] = useState(null);
  const [weekNumber, setWeekNumber] = useState(1);
  const { black, white } = colors;
  const [controller] = useMaterialUIController();

  const { darkMode } = controller;

  const [anchorEl, setAnchorEl] = useState(null);
  const [filterOption, setFilterOption] = useState(weeklyOptions[0].id);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function getWeekNumber(date) {
    const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - firstDayOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
  }

  const fetchWeeklyData = async (
    userId,
    page,
    start,
    end,
    filterOption,
    weekNumber
  ) => {
    setLoading(true);
    try {
      let formattedStartDate = start;
      let formattedEndDate = end;
      let apiWeek = null;

      if (filterOption === "thisWeek") {
        formattedStartDate = null;
        formattedEndDate = null;
        apiWeek = getWeekNumber(new Date());
      } else if (filterOption === "lastWeek") {
        formattedStartDate = null;
        formattedEndDate = null;
        apiWeek = getWeekNumber(new Date()) - 1;
      } else if (filterOption === "lastMonth") {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 1);
        formattedStartDate = startDate.toISOString().split("T")[0];
        formattedEndDate = endDate.toISOString().split("T")[0];
        apiWeek = null;
      } else if (filterOption === "3Months") {
        const endDate = new Date();
        endDate.setDate(endDate.getDate() + 1);
        const startDate = new Date();
        startDate.setMonth(endDate.getMonth() - 3);
        formattedStartDate = startDate.toISOString().split("T")[0];
        formattedEndDate = endDate.toISOString().split("T")[0];
        apiWeek = null;
      } else if (filterOption === "custom") {
        formattedStartDate = start;
        formattedEndDate = end;
      } else if (filterOption === "customWeek") {
        formattedStartDate = null;
        formattedEndDate = null;
        apiWeek = weekNumber;
      } else {
        formattedStartDate = null;
        formattedEndDate = null;
      }

      const response = await getWeeklyPortfolio(
        userId,
        page,
        formattedStartDate,
        formattedEndDate,
        apiWeek
      );
      if (response.records) {
        setWeeklyData(response?.records);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const handleStartDateChange = (newValue) => {
    setStartDate(newValue ? dayjs(newValue).format("YYYY-MM-DD") : null);
  };

  const handleEndDateChange = (newValue) => {
    setEndDate(newValue ? dayjs(newValue).format("YYYY-MM-DD") : null);
    const adjustedEndDate = newValue
      ? dayjs(newValue).add(1, "day").format("YYYY-MM-DD")
      : null;
    setEnd(adjustedEndDate);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    // if (!token) return false;
    const decodedToken = jwtDecode(token);
    const userId = decodedToken.UserID;
    if (page && userId) {
      fetchWeeklyData(userId, page, startDate, end, filterOption, weekNumber);
    }
  }, [page, startDate, end, filterOption, weekNumber]);

  const handleClearFields = (option) => {
    if (option === "custom") {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const GridHeader = ({ text }) => (
    <MDTypography
      sx={{ fontWeight: 550, paddingLeft: "17px", fontSize: "17px" }}
    >
      {text}
    </MDTypography>
  );

  const columns = [
    {
      field: "market",
      headerName: <GridHeader text="Exchange" />,
      sortable: false,
      filterable: false,
      width: isMedium ? 150 : undefined,
      flex: isMedium ? undefined : 1,
      renderCell: (params) => (
        <MDTypography
          sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
        >
          {params.value}
        </MDTypography>
      ),
    },
    {
      field: "weekNumber",
      headerName: <GridHeader text="Week" />,
      sortable: false,
      filterable: false,
      width: isMedium ? 150 : undefined,
      flex: isMedium ? undefined : 1,
      renderCell: (params) => (
        <MDTypography
          sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
        >
          {params.value}
        </MDTypography>
      ),
    },
    {
      field: "startDate",
      headerName: <GridHeader text="Start Date" />,
      sortable: false,
      filterable: false,
      width: isMedium ? 150 : undefined,
      flex: isMedium ? undefined : 1,
      renderCell: (params) => (
        <MDTypography
          sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
        >
          {params.value}
        </MDTypography>
      ),
    },
    {
      field: "endDate",
      headerName: <GridHeader text="End Date" />,
      sortable: false,
      filterable: false,
      width: isMedium ? 150 : undefined,
      flex: isMedium ? undefined : 1,
      renderCell: (params) => (
        <MDTypography
          sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
        >
          {params.value}
        </MDTypography>
      ),
    },
    {
      field: "weekPNL",
      headerName: <GridHeader text="Week P&L" />,
      sortable: false,
      filterable: false,
      width: isMedium ? 150 : undefined,
      flex: isMedium ? undefined : 1,
      renderCell: (params) => (
        <MDTypography
          sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
        >
          {formatAndMultiplyValueAssetItem(params.value, params.row.market)}
        </MDTypography>
      ),
    },
    {
      field: "brokerage",
      headerName: <GridHeader text="Brokerage" />,
      sortable: false,
      filterable: false,
      width: isMedium ? 150 : undefined,
      flex: isMedium ? undefined : 1,
      renderCell: (params) => (
        <MDTypography
          sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
        >
          {formatAndMultiplyValueAssetItem(params.value, params.row.market)}
        </MDTypography>
      ),
    },
    {
      field: "Total",
      headerName: <GridHeader text="Total" />,
      sortable: false,
      filterable: false,
      width: isMedium ? 150 : undefined,
      flex: isMedium ? undefined : 1,
      renderCell: (params) => (
        <MDTypography
          sx={{ paddingLeft: "20px", paddingTop: "5px", fontSize: "18px" }}
        >
          {formatAndMultiplyValueAssetItem(params.value, params.row.market)}
        </MDTypography>
      ),
    },
  ];

  const rows = weeklyData?.map((item) => ({
    id: item?.id,
    startDate: format(item?.week_start_date, "dd-MM-yyyy"),
    endDate: format(item?.week_end_date, "dd-MM-yyyy"),
    weekPNL: item?.portfolio_value,
    brokerage: item?.brokerage,
    market: item?.exchange,
    weekNumber: item?.week_number,
    Total: item?.total,
  }));

  function customNoRows() {
    return (
      <MDBox
        height={"100%"}
        width={"100%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MDTypography sx={{ fontSize: isMedium ? "15px" : "20px" }}>
          No Settlements Found!
        </MDTypography>
      </MDBox>
    );
  }

  const weekCount = Array.from(
    { length: getWeekNumber(new Date()) },
    (_, i) => i + 1
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid xl={12} lg={12} md={12} sm={12} xs={12} marginTop={2} container>
        <Grid
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          sx={{
            display: "flex",
            flexDirection:
              filterOption === "custom" || filterOption === "customWeek"
                ? "row"
                : "column",
            justifyContent: "flex-end",
            flexWrap: "wrap",
            alignItems: "flex-end",
          }}
          my={3}
        >
          <Grid  xs={3}
          sm={2}
          lg={1}
          md={1}
          xl={0.8}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}>
            <IconButton onClick={handleClick} data-testid="filter-icon-button">
              <EditCalendarIcon
                fontSize="large"
                sx={{ color: darkMode ? white.main : "default" }}
              />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: darkMode ? "inherit" : white.main,
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              {weeklyOptions.map((option) => (
                <MenuItem
                  key={option.id}
                  onClick={() => {
                    setFilterOption(option.id), 
                    handleClose(), 
                    handleClearFields(option.id)
                  }}
                  selected={option.id === filterOption}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </Grid>
          {filterOption === "custom" && (
            <Grid
              xs={12}
              sm={12}
              lg={7}
              md={7}
              xl={5}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <Grid xs={12} sm={12} lg={5.7} md={5.7} xl={5.8}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="From"
                        slotProps={{
                          field: { clearable: true },
                          openPickerButton: {
                            color: darkMode ? "white" : "muted",
                          },
                        }}
                        value={dayjs(startDate)}
                        maxDate={today}
                        onChange={(newValue) => (
                          setStart(newValue), handleStartDateChange(newValue)
                        )}
                        renderInput={(params) => <TextField {...params}/>}
                      />
                </LocalizationProvider>
              </Grid>
              <Grid xs={12} sm={12} lg={5.7} md={5.7} xl={5.8}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="To"
                        slotProps={{
                          field: { clearable: true },
                          openPickerButton: {
                            color: darkMode ? "white" : "muted",
                          },
                        }}
                        value={dayjs(endDate)}
                        minDate={
                          startDate ? dayjs(startDate).add(1, "day") : null
                        }
                        maxDate={today}
                        onChange={(newValue) => handleEndDateChange(newValue)}
                        renderInput={(params) => <TextField {...params} />}
                      />
                </LocalizationProvider>
              </Grid>
            </Grid>
          )}
          {filterOption === "customWeek" && (
            <Grid
            lg={3} xl={1.8} sm={3} md={3} xs={6} sx={{marginRight: filterOption === "custom" ? "15px":"0px"}}
            >
              <Autocomplete
                options={weekCount}
                onChange={(event, newValue) => {
                  setWeekNumber(newValue);
                }}
                getOptionLabel={(option) => option.toString()}
                renderInput={(params) => (
                  <TextField {...params} label="select Week" />
                )}
              />
            </Grid>
          )}
        </Grid>
        <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
          <DataGrid
            disableColumnMenu
            rows={rows}
            columns={columns}
            hideFooter={true}
            rowHeight={45}
            loading={loading}
            slots={{
              noRowsOverlay: customNoRows,
            }}
            disableSelectionOnClick
            disableRowSelectionOnClick
            hideFooterPagination
            disableColumnResize
            autoHeight
            classes={{ display: "flex", cursor: "pointer" }}
            sx={{
              "& .MuiDataGrid-cell:focus": {
                outline: "none", 
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none",
              },
              "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
                outline: "none",
              },
            }}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}
export default weekly;
