import React, { useState, useEffect, useRef } from "react";
import { Autocomplete, TextField, Grid, Card } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SearchBar from "layouts/dashboard/components/SearchBar";
import Watchlists from "./components/WatchList";
import { watchlist } from "../../services/portifolioapis";
import useSocket from "Sockets/Socket";

function Watchlist() {
  const [assetData, setAssetData] = useState([]);
  const [data, setData] = useState([]);
  const [records, setRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [market, setMarket] = useState(null);
  const token = sessionStorage.getItem("token");

  //another method
  const previousValues = useRef({});
  const previousBidValues = useRef({});
  const previousAskValues = useRef({});

  useEffect(() => {
    const enabled = sessionStorage.getItem("enabledmarkets");
    const exchange = sessionStorage.getItem("selectedExchange");
    if (enabled) {
      const markets = JSON.parse(enabled);
      const formattedOptions = markets.map((market) => ({
        label: market,
        value: market,
      }));
      setOptions(formattedOptions);
      if (formattedOptions.length > 0) {
        const defaultMarket = formattedOptions.find(
          (option) => option.value === exchange
        );
        setMarket(defaultMarket || formattedOptions[0]);
      }
    }
  }, []);

  useEffect(() => {
    if (market && token) {
      fetchData1(market);
    }
  }, [token, market, page]);

  useEffect(() => {
    if (data) {
      setAssetData(
        data.map((item) => ({
          FinancialInstrumentID: item?.FinancialInstrumentID,
          name2: item?.Name,
          name3: item?.Exchange,
          instrumentid: item?.FinancialInstrumentID,
          instrumentType: item?.Exchange,
          TradingSymbol: item?.TradingSymbol,
          AskPrice: item?.AskPrice,
          BidPrice: item?.BidPrice,
        }))
      );
    }
  }, [data]);

  const handleDataReceived = (data) => {
    if(assetData.length > 0){
        const updatedData = assetData.map((item) => {
        const symbol = item?.TradingSymbol;
        const socketItem = data[item?.TradingSymbol];
        // console.log('socket item:', socketItem)

        const socketPrice = socketItem?.last_price ==='None' ? 0 : socketItem?.last_price
        // console.log("socket Price:", socketPrice)
        
        const previousValue = previousValues.current[symbol]?.last_price || socketPrice;
        previousValues.current[symbol] =  { last_price: socketItem ? socketItem.last_price : item?.LastPrice };

        // previousValues.current[symbol] = {last_price: socketPrice  };
        // console.log("previous socket price:", previousValue)

        const socketBidPrice = socketItem?.bid_price==="None"?0:socketItem?.bid_price
        // console.log("socket bid price:", socketBidPrice)

        const previousBidValue = previousBidValues.current[symbol]?.bid_price || socketBidPrice;
        previousBidValues.current[symbol] = { bid_price: socketItem ? socketItem.bid_price : item?.BidPrice };

        // previousBidValues.current[symbol] = { bid_price: socketBidPrice };
        // console.log("previous bid socket price:", previousBidValue)

        const socketAskPrice = socketItem?.ask_price==="None"?0:socketItem?.ask_price
        // console.log("socket ask price:", socketAskPrice)

        const previousAskValue=previousAskValues.current[symbol]?.ask_price || socketAskPrice;
        previousAskValues.current[symbol] = { ask_price: socketItem ? socketItem.ask_price : item?.AskPrice };

        // previousAskValues.current[symbol] = { ask_price: socketAskPrice };
        // console.log("previous ask socket price:", previousAskValue)

        return {
          ...item,
          LastPrice: socketPrice && socketPrice,
          previousPrice: previousValue ,
          bidPrice: socketBidPrice && socketBidPrice,
          previousBidPrice: previousBidValue ,
          askPrice:socketAskPrice && socketAskPrice,
          previousAskPrice:previousAskValue
        };
      });
      setAssetData(updatedData);
      // setLoading(false);
    }
  };

  
  const { connectSocket, closeSocket } = useSocket(
    market?.label=== "NASDAQ" || market?.label === "CRYPTO"
      ? `${process.env.REACT_APP_SOCKET_URL}/getNASDAQSymbolsDetails`
      : `${process.env.REACT_APP_SOCKET_URL}/getNSESymbolsDetails`,
    assetData.map((item) => item.TradingSymbol).join(","),
    handleDataReceived
  );

  useEffect(() => {
    if (market && assetData) {
      connectSocket();
    }
    return () => {
      closeSocket();
    };
  }, [market]);

  const fetchData1 = async (market) => {
    setLoading(true);
    try {
      const marketData = {
        exchange: market.label,
        pageSize: 10,
        page: page,
      };
      const response1 = await watchlist(marketData);
      setData(response1.instruments);
      setRecords(response1.pagination.totalRecords);
    } catch (error) {
      console.error("Error in API calls:", error);
    } finally{
      setLoading(false)
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid
      container
        lg={12}
        md={12}
        sm={12}
        xl={12}
        xs={12}
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end",
          flexWrap: "wrap",
          paddingRight: "15px",
          gap: "10px",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Grid lg={4.6} xs={12} sm={12} md={7} xl={4.6} container>
          <SearchBar fetchData1={() => fetchData1(market)} />
        </Grid>
        <Grid lg={3} xl={2} sm={12} md={3} xs={12}>
          <Autocomplete
            value={market}
            onChange={(event, newValue) => {
              setMarket(newValue);
              setAssetData([]);
              fetchData1(newValue);
            }}
            options={options}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                sx={{ "& .MuiInputBase-root": { height: 45 } }}
              />
            )}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
          />
        </Grid>
      </Grid>
      <Card>
        <Watchlists
          assetData={assetData}
          count={records}
          page={page}
          setPage={setPage}
          loading={loading}
          selected={market}
          fetchData1={() => fetchData1(market)}
        />
      </Card>
    </DashboardLayout>
  );
}

export default Watchlist;
