import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CalenderCard from './components/CalenderCard'
import {Card} from '@mui/material'

function Calender() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
        <CalenderCard />
    </DashboardLayout>
  );
}
export default Calender;
