import React, { useEffect, useRef, useState } from "react";
// TRADING VIEW CART WIDGET IMPORT
import { createChart } from "lightweight-charts";
import MDBox from "components/MDBox";
// MUI COMPONENTS IMPORT
import {Card, Grid, useMediaQuery, useTheme as T } from "@mui/material";
// CUSTOM COMPONENTS IMPORT FOR THEME
import { useMaterialUIController } from "context";
// JWT DECODE IMPORT FOR TOKEN DECODE
import {jwtDecode} from "jwt-decode";
// API IMPORT FOR TRADE DATES AND VALUES
import { get_net_trade_values_by_date } from "services/profileapis";
// COMPONENT LOADER
import CircularProgress from "@mui/material/CircularProgress";

// CHART COMPONENT
const Chart = ({ selected, days, marketContext }) => {
  const chartContainerRef = useRef(null);
  const t = T();
  const isSmall = useMediaQuery(t.breakpoints.down("md"));
  const [controller] = useMaterialUIController();
  const [dates, setDates] = useState([]);
  const [times, setTimes] = useState([]);
  const [loading, setLoading] = useState(false);
  const { darkMode } = controller;
  const token = sessionStorage.getItem("token");
  const decoded = jwtDecode(token);
  const id = decoded.UserID;

  // FOR CHART CONTAINER
  useEffect(() => {
    if (chartContainerRef.current && chartContainerRef.current.offsetWidth > 0) {
      const chart = createChart(chartContainerRef.current, {
        height: 250,
        rightPriceScale: {
          visible: true,
          borderVisible: true,
          autoScale: true,
          borderColor: darkMode ? "#71649C" : "#B2BEB5",
        },
        leftPriceScale: {
          visible: false,
          borderVisible: false,
          autoScale: true,
        },
        timeScale: {
          timeVisible: false,
          minBarSpacing: 0.001,
          borderVisible: true,
          fixLeftEdge: true,
          fixRightEdge: true,
          borderColor: darkMode ? "#71649C" : "#B2BEB5",
        },
        layout: {
          background: { color: darkMode ? "#202940" : "#fff" },
          textColor: darkMode ? "#DDD" : "#000",
        },
        grid: {
          vertLines: { color: darkMode ? "#444" : "#EEEBEA" },
          horzLines: { color: darkMode ? "#444" : "#EEEBEA" },
        },
      });

      // Area Series
      const areaSeries = chart.addAreaSeries({
        topColor: "#2962FF",
        bottomColor: "rgba(41, 98, 255, 0.28)",
        lineColor: "#2962FF",
        lineStyle: 0,
        lineWidth: 3,
        crosshairMarkerVisible: true,
        crosshairMarkerRadius: 3,
      });

      areaSeries.priceScale().applyOptions({
        scaleMargins: {
          top: 0.1,
          bottom: 0.1,
        },
      });

      const areaData = dates.map((time, index) => ({
        time: time,
        value: parseFloat(times[index]),
      }));

      areaSeries.setData(areaData);
      chart.timeScale().fitContent();

      return () => {
        chart.remove();
      };
    }
  }, [dates, times, darkMode]);

  // TO UPDATE CHART DATES AND VALUES
  useEffect(() => {
    const todayDate = new Date().toLocaleDateString("fr-CA");
    const getMarketData = marketContext?.find((item) => item?.id === selected);
    if (getMarketData) {
      if (!dates?.includes(todayDate)) {
        setDates((prevDates) => [...prevDates, todayDate]);
        setTimes((prevTimes) => [...prevTimes, getMarketData?.totalValue || 0]);
        // console.log("if")
        setLoading(false)
      } else {
        setTimes((prevTimes) => {
          const newData = [...prevTimes];
          newData[newData?.length - 1] = getMarketData?.totalValue || 0;
          return newData;
        });
        // console.log("else")
        setLoading(false)
      }
    }
    setLoading(false)
  }, [selected, marketContext]);


  // API CALL TO GET CHART DATA
  const graphData = async (id, selected, days) => {
    setLoading(true);
    try {
      const responseData = await get_net_trade_values_by_date(
        id,
        selected,
        days
      );
      setDates(responseData?.date || []);
      setTimes(responseData?.value || []);
    } catch (err) {
      console.error("Failed to fetch trade values:", err);
    } finally{
      setLoading(false)
    }
  };

  // API CALL TO GET CHART DATA
  useEffect(() => {
    if (id && selected) {
      graphData(id, selected, days);
    }
  }, [id, selected, days]);

  // COMPONENT RENDERING
  return (
    <Grid lg={12} xl={12} sm={12} md={12} xs={12}>
      {loading ? (
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: 250,
            marginX: isSmall ? "0px" : "20px",
            marginY: "20px",
            boxShadow: 3,
            borderRadius: 3,
            overflow: "hidden",
          }}
        >
          <CircularProgress color="success" />
        </Card>
      ) : (
        <MDBox
          ref={chartContainerRef}
          sx={{
            height: 250,
            marginRight: isSmall ? "0px" : "20px",
            marginY: "20px",
            boxShadow: 3,
            paddingTop:"5px",
            backgroundColor: darkMode ? "#202940" : "#fff",
            borderRadius: 2,
            overflow: "hidden",
          }}
        />
      )}
    </Grid>
  );
};

export default Chart;
