const fast = process.env.REACT_APP_FAST_API_URL;

export const fetchCalendarData = async (exchange) => {
    try {
        const token = sessionStorage.getItem("token");
        if (!token) {
            throw new Error("Authentication token not found in session storage");
        }
        const response = await fetch( `${fast}/holidays/by-type/?type=${exchange}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        if (!response.ok) { 
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching Calender Data:", error);
        throw error;
    }
}