
// all compoents in project 
import Dashboard from "layouts/dashboard";
import Watchlist from "layouts/watchlist";
import Header from "layouts/profile/components";
import support from "./layouts/support";
import Calender from "layouts/Calender";
import TransactionHistory from "layouts/transactionHistory";
import TradeHistory from "layouts/tradeHistory";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import Cover from "layouts/authentication/reset-password/cover";
import weekly from "layouts/settleMents";
import Orders from "layouts/Orders";


// For auth protection
import AuthGuard from "AuthGuard";

// @mui icons
import Icon from "@mui/material/Icon";
import TermsAndConditions from "layouts/authentication/Terms-and-conditions";

// Routes---------------------->
const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <AuthGuard component={Dashboard} />,
  },
  {
    type: "collapse",
    name: "Watchlist",
    key: "watchlist",
    icon: <Icon fontSize="small">bookmark_added</Icon>,
    route: "/watchlist",
    component: <AuthGuard component={Watchlist} />,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "Orders",
    icon: <Icon fontSize="small">inventory</Icon>,
    route: "/Orders",
    component: <AuthGuard component={Orders} />,
  },
  {
    type: "collapse",
    name: "Order History",
    key: "order-history",
    icon: <Icon fontSize="small">description</Icon>,
    route: "/order-history",
    component: <AuthGuard component={TradeHistory} />,
  },
  {
    type: "collapse",
    name: "Transaction History",
    key: "transaction-history", // key should be same as route or bgColor wont set
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/transaction-history",
    component: <AuthGuard component={TransactionHistory} />,
  },
  {
    type: "collapse",
    name: "Weekly Portfolio",
    key: "weekly-portfolio",
    icon: <Icon fontSize="small">trending_up</Icon>,
    route: "/weekly-portfolio",
    component: <AuthGuard component={weekly} />,
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/profile",
    component: <AuthGuard component={Header} />,
  },
  {
    type: "collapse",
    name: "Calendar",
    key: "calendar",
    icon: <Icon fontSize="small">calendar_month</Icon>,
    route: "/calendar",
    component: <AuthGuard component={Calender} />,
  },
  {
    type: "collapse",
    name: "Support",
    key: "support",
    icon: <Icon fontSize="small">support_agent</Icon>,
    route: "/support",
    component: <AuthGuard component={support} />,
  },
  {
    route: "/login",
    component: <SignIn />,
  },
  {
    route: "/termsandconditions",
    component: <TermsAndConditions />,
  },
  {
    route: "/signup",
    component: <SignUp />,
  },
  {
    route: "/set-password",
    component: <AuthGuard component={Cover} />,
  },
 
];

export default routes;
