import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const showToast = (message, type) => {
    toast(message, {
        type,
        // position: "bottom-center",
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
            fontSize: '17px',
            borderRadius: '15px',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        },
        // icon: type === 'success' ? '✔️' : type === 'error' ? '❌' : 'ℹ️',
        theme: 'colored'
    });
};

export default showToast;