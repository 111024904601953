const go = process.env.REACT_APP_GO_URL;
const fast = process.env.REACT_APP_FAST_API_URL;
import { jwtDecode } from "jwt-decode";
//const token = sessionStorage.getItem("token");
//const access  = sessionStorage.getItem('token')

export const fetchWallet = async (userid, exchange) => {
  // console.log("Wallet response status:", userid, exchange);
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }
    const response = await fetch(`${fast}/wallets/${userid}/${exchange}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    //console.log("Wallet response status:", response.status); // Print response status

    if (response.ok) {
      const responseData = await response.json();
      // console.log("Wallet response data:", responseData); // Print response data
      return responseData;
    } else {
      throw new Error("Network response was not ok.");
    }
  } catch (error) {
    console.error("Error fetching wallet:", error);
    throw error;
  }
};

export const fetchUserProfile = async () => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }
    const response = await fetch(`${go}/api/user/profile`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      redirect: "follow",
    });
    // console.log("profile response:+++++++++++++++++++", response);

    if (response.ok) {
      const result = await response.json();
      return result;
    } else {
      // console.error("Error fetching data:", response.status);
      return null;
    }
  } catch (error) {
    console.error("Error:", error);
    return null;
  }
};

export const updateNameApi = async (firstName, lastName) => {
  const token = sessionStorage.getItem("token");
  if (!token) {
    throw new Error("Authentication token not found in session storage");
  }
  const requestOptions = {
    method: "POST",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      firstName: firstName,
      lastName: lastName,
      Currency: "INR", // Replace with the actual currency
    }),
  };

  try {
    const response = await fetch(
      `${go}/api/user/updateProfile`, // Replace with your actual API endpoint
      requestOptions
    );

    if (response.ok) {
      const result = await response.text();
      return result;
    } else {
      throw new Error(`API request failed with status ${response.status}`);
    }
  } catch (error) {
    throw new Error(`API request failed: ${error.message}`);
  }
};

export const updatepassword = async (
  currentPassword,
  newPassword,
  confirmPassword
) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`, // Use authToken instead of token
      },
      body: JSON.stringify({
        currentPassword: currentPassword,
        newPassword: newPassword,
      }),
    };

    const response = await fetch(
      `${go}/api/user/changePassword`,
      requestOptions
    );

    if (response.ok) {
      const result = await response.text();
      return { success: true, message: result };
    } else {
      console.error("Error Updating Password:", response.statusText);
      return { success: false, error: "Current Password Incorrect" };
    }
  } catch (error) {
    console.error(`API request failed with error: ${error.message}`);
    throw error;
  }
};

export default updatepassword;

// raising new ticket api
export const Support = async (data) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const raw = JSON.stringify(data);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(`${fast}/create-ticket`, requestOptions);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json(); // Return the response data
  } catch (error) {
    console.error("There was a problem with the Support API request:", error);
    throw error;
  }
};
// get user tickets
export const Supportlist = async (userid) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }

    const response = await fetch(`${fast}/tickets/by-user/${userid}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error(
      "There was a problem with the Supportlist API request:",
      error
    );
    throw error;
  }
};

export const get_net_trade_values_by_date = async (user_id, exchange, days) => {
  const url = days
    ? `${fast}/getGraphData/${user_id}/${exchange}?days=${days}`
    : `${fast}/getGraphData/${user_id}/${exchange}`;

  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const getBalances = async () => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }

    const id = jwtDecode(token);
    const userid = id.UserID;

    const response = await fetch(`${fast}/wallets/balance/${userid}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("responce getBalances", response);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    return response.json();
  } catch (error) {
    console.error("Error fetching balances  getBalances:", error);
    throw error; // Propagate the error
  }
};

export const accountDeletion = async () => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }
    const decode = jwtDecode(token);
    const id = decode.UserID;
    const response = await fetch(`${go}/api/user/deteleUser`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      console.error("HTTP Response Error: ", response.status);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Problem Deletion Account:", error);
    throw error;
  }
};

export const getCalender = async (market) => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found in session storage");
    }
    const response = await fetch(
      `${process.env.REACT_APP_FAST_API_URL}/holidays/by-type/?type=${market}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (!response.ok) {
      console.error("HTTP Response Error: ", response.status);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("could not fetch maintainence text:", error);
    throw error;
  }
};

export const getNotifications = async () => {
  try {
    const token = sessionStorage.getItem("token");
    if (!token) {
      throw new Error("Authentication token not found");
    }
    const id = jwtDecode(token);
    const userid = id.UserID;
    const response = await fetch(`${fast}/user/notifications/${userid}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("could not delete notification", error);
  }
};
