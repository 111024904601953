import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Card,
  Grid,
  Box,
  useMediaQuery,
  useTheme as T,
} from "@mui/material";
import {
  removeSelectedFromWatchlist,
} from "../../../services/portifolioapis";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import { useNavigate } from "react-router-dom";
import MDTypography from "components/MDTypography";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pagination from "@mui/material/Pagination";
import Tooltip from "@mui/material/Tooltip";
import colors from "assets/theme/base/colors";
import { useMaterialUIController } from "context";
import { formatAndMultiplyValueAssetItem } from "utils/formatAndMultiplyValue";
import showToast from "utils/toastUtils";

const watchlist = ({
  assetData,
  count,
  page,
  setPage,
  loading,
  selected,
  fetchData1,
}) => {
  const t = T();
  const isMedium = useMediaQuery(t.breakpoints.down("lg"));
  const navigate = useNavigate();
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const { white, black } = colors;
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode } = controller;

  const RemoveFromWatchlists = async () => {
    console.log(rowSelectionModel);
    if (rowSelectionModel.length === 0) {
      showToast("Please select a stock.", "warning");
      return;
    }
    try {
      const data = {
        instrumentIds: rowSelectionModel,
        exchange: selected.label,
      };
      const response = await removeSelectedFromWatchlist(data);
      console.log("removedata", data);

      if (response) {
        showToast("Removed  from watchlist!", "success");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        fetchData1();
      }
      // removeFromWatchlist();
    } catch (err) {
      console.error("Error in performing remove selected from watchlist:", err);
    }
  };

  // Function to get instrumentType based on instrumentId from assetData

  const GridHeader = ({ text }) => (
    <MDTypography sx={{ fontWeight: 550, paddingLeft: "20px", fontSize:'18px' }}>
      {text}
    </MDTypography>
  );

  const columns = [
    {
      field: "name2",
      headerName: <GridHeader text="Name" />,
      sortable: false,
      filterable: false,
      width: isMedium ? 200 : undefined, 
      flex: isMedium ? undefined : 1,
      renderCell: (params) => {
        return (
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              padding:'0px'
            }}
          >
            <MDTypography sx={{ paddingLeft: "20px", fontWeight: 500, fontSize:'18px', lineHeight:'18px' }}>
              {params.row.Symbol}
            </MDTypography>
            <Tooltip title={params.value} placement="top" arrow>
              <MDTypography
                sx={{
                  paddingLeft: "20px",
                  fontSize: "12px",
                  textOverflow: "ellipsis", // Hide overflow with an ellipsis
                  overflow: "hidden", // Necessary for ellipsis
                  whiteSpace: "nowrap", // Keep text on a single line
                }}
              >
                {params.value}
              </MDTypography>
            </Tooltip>
          </MDBox>
        );
      },
    },
    {
      field: "bidPrice",
      headerName: <GridHeader text="Bid Price" />,
      width: isMedium ? 200 : undefined, 
      flex: isMedium ? undefined : 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const priceColor =
          params.value > params.row.PreviousBidPrice
            ? "success"
            : params.value < params.row.PreviousBidPrice
            ? "error"
            : "dark";
        return (
          <MDTypography
          color={priceColor}
            sx={{
              paddingLeft: "20px",
              paddingTop: "15px",
              fontSize:'18px'
            }}
          >
            {formatAndMultiplyValueAssetItem(params?.value, params.row.instrumentType)}
          </MDTypography>
        );
      },
    },
    {
      field: "askPrice",
      headerName: <GridHeader text="Ask Price" />,
      width: isMedium ? 200 : undefined, 
      flex: isMedium ? undefined : 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const priceColor =
        params.value > params.row.PreviousAskPrice
          ? "success"
          : params.value < params.row.PreviousAskPrice
          ? "error"
          : "dark";
        return (
          <MDTypography sx={{ paddingLeft: "20px", paddingTop: "15px", fontSize:'18px' }} color={priceColor}>
            {formatAndMultiplyValueAssetItem(params?.value, params.row.instrumentType)}
          </MDTypography>
        );
      },
    },
    {
      field: "LastPrice",
      headerName: <GridHeader text="Price" />,
      width: isMedium ? 200 : undefined, 
      flex: isMedium ? undefined : 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const priceColor =
        params.value > params.row.PreviousPrice
          ? "success"
          : params.value < params.row.PreviousPrice
          ? "error"
          : "dark";
        return (
          <MDTypography
          color={priceColor}
            sx={{
              paddingLeft: "20px",
              paddingTop: "15px",
              fontSize:'18px'
            }}
          >
            {formatAndMultiplyValueAssetItem(params.row.LastPrice, params.row.instrumentType)}
          </MDTypography>
        );
      },
    },
  ];

  function customNoRows() {
    return (
      <MDBox
        height={"100%"}
        width={"100%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MDTypography sx={{}}>Your watchlist is currently empty!</MDTypography>
      </MDBox>
    );
  }

  //
  // Map AssetData to rows
  const rows = assetData.map((asset, index) => ({
    id: asset?.FinancialInstrumentID,
    name2: asset?.name2,
    price: asset?.Price,
    name3: asset?.name3,
    instrumentid: asset?.FinancialInstrumentID,
    instrumentType: asset?.instrumentType,
    Symbol: asset?.TradingSymbol,
    LastPrice:  asset?.LastPrice || asset?.Price || 0,
    PreviousPrice: asset?.previousPrice,
    askPrice: asset?.askPrice || asset.AskPrice,
    PreviousAskPrice: asset?.previousAskPrice,
    bidPrice: asset?.bidPrice || asset.BidPrice,
    PreviousBidPrice: asset?.previousBidPrice,
  }));

  const handleClick = (row) => {
    navigate("/charts", {
      state: {
        instrumentId: row?.instrumentid,
        instrumentType: row?.instrumentType,
        TradingSymbol: row?.Symbol,
        isMCX: row?.instrumentType,
      },
    });
  };

  const handleChange = (event, newPage) => {
    setPage(newPage);
  };
  return (
    <div style={{ width: "100%" }}>
      <Card>
        <div style={{ cursor: "pointer" }}>
          <DataGrid
            checkboxSelection
            disableColumnMenu
            rows={rows}
            columns={columns}
            rowHeight={55}
            slots={{
              noRowsOverlay: customNoRows,
            }}
            //checkboxSelection
            //getRowId={(data)=>`${data.id}-${data.instrumentType}`}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            hideFooter={true}
            disableRowSelector={false}
            disableSelectionOnClick
            disableColumnResize
            hideFooterPagination
            autoHeight
            onRowClick={(params) => handleClick(params.row)} // Call handleC
            loading={loading}
            sx={{
              "& .MuiDataGrid-cell:focus": {
                outline: "none", // Removes the outline on focus
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none", // Covers cases where the cell might have inner focusable elements
              },
              "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
                outline: "none", // Removes the outline on focus for column headers
              },
              // '& .MuiDataGrid-row:hover': {
              //   backgroundColor: 'rgba(70, 130, 180, 0.5)', // Light blue color on row hover
              // },
            }}
          />
        </div>
        <Box display={"flex"} justifyContent={"flex-end"} marginTop={"20px"}>
          <Pagination
            count={Math.ceil(count / 10)}
            page={page}
            onChange={handleChange}
            sx={{
              "& .MuiPaginationItem-root": {
                color: darkMode ? white.main : black.main,
                "& .MuiSvgIcon-root": {
                  color: darkMode ? white.main : black.main,
                },
                "&.Mui-selected": {
                  color: darkMode ? white.main : black.main,
                  bgcolor: "rgba(0, 0, 0, 0.12)",
                },
              },
            }}
          />
        </Box>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              padding: "20px",
              justifyContent: "flex-end",
            }}
          >
            <MDButton
              variant="gradient"
              color="info"
              onClick={RemoveFromWatchlists}
            >
              Remove From WatchList
            </MDButton>
          </Grid>
        </Grid>
      </Card>
      <ToastContainer />
    </div>
  );
};

export default watchlist;
