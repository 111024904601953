import React from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Tickets from './components/Tickets'

function support() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
        <Tickets/>
    </DashboardLayout>
  );
}
export default support;
