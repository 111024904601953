import React, { useEffect, useState, useRef } from "react";
import { DataGrid } from "@mui/x-data-grid";
import Card from "@mui/material/Card";
import { useTheme as T, useMediaQuery,Box } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import useNavigate hook for navigation
import MDTypography from "components/MDTypography";
import Pagination from "@mui/material/Pagination";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBox";
import colors from "assets/theme/base/colors";
import { useMaterialUIController } from "context";
import { formatAndMultiplyValueAssetItem } from "utils/formatAndMultiplyValue";

const CardItemsNasdaq = ({ list, page, setPage, rowCount, loading }) => {
  const navigate = useNavigate(); // Get navigate function for navigation
  const { white, black } = colors;
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const t = T();
  const isMedium = useMediaQuery(t.breakpoints.down("xl"));

  // console.log(askPrice, bidPrice)

  const GridHeader = ({ text, nextText }) => (
    <MDBox sx={{ display: "flex", flexDirection: "column", pointerEvents: "none"}} >
      <MDTypography
        sx={{
          fontWeight: 520,
          paddingLeft: "18px",
          fontSize: "15px",
          lineHeight: "15px",
        }}
      >
        {text}
      </MDTypography>
      <MDTypography
        sx={{
          fontWeight: 520,
          paddingLeft: "18px",
          fontSize: "15px",
          lineHeight: "15px",
        }}
      >
        {nextText}
      </MDTypography>
    </MDBox>
  );

  const cardItemColumns = [
    {
      field: "instrumentName",
      headerName: <GridHeader text="Symbol" nextText={"Buy/Sell"} />,
      width: isMedium ? 250 : undefined,
      flex: isMedium ? undefined : 1,
      position: "sticky",
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
            }}
          >
            <Tooltip title={params.row.instrumentName} placement="top" arrow>
              <MDTypography
              data-testid="symbol-name"
                sx={{
                  fontWeight: 510,
                  paddingLeft: "20px",
                  fontSize: "14px",
                  lineHeight: 1.3,
                }}
              >
                {params.value}
              </MDTypography>
            </Tooltip>
            <MDTypography
              color={params.row.quantity < 0 ? "error" : "success"}
              sx={{
                paddingLeft: "20px",
                fontSize: "13px",
                fontWeight: 510,
                lineHeight: 1.3,
              }}
            >
              {params.row.quantity < 0 ? "Sell" : "Buy"}
            </MDTypography>
          </MDBox>
        );
      },
    },
    {
      field: "quantity",
      headerName: <GridHeader text="Quantity" />,
      width: isMedium ? 130 : undefined,
      flex: isMedium ? undefined : 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <MDTypography
          color={params.value < 0 ? "error" : "success"}
          sx={{
            paddingLeft: "20px",
            fontSize: "16px",
            fontWeight: 500,
            paddingTop: "10px",
          }}
        >
          {Math.abs(params.value)}
        </MDTypography>
      ),
    },
    {
      field: "avgvalue",
      headerName: <GridHeader text="Avg Price" />,
      width: isMedium ? 250 : undefined,
      flex: isMedium ? undefined : 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        return (
          <MDTypography
            sx={{ paddingLeft: "20px", paddingY: "5px", fontSize: "16px" }}
          >
            {formatAndMultiplyValueAssetItem(params.value / params.row.quantity, params.row.instrumentType)}
          </MDTypography>
        );
      },
    },
    {
      field: "AskPrice",
      headerName: <GridHeader text="Current Price" />,
      width: isMedium ? 250 : undefined,
      flex: isMedium ? undefined : 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const displayPrice =
          parseInt(params.row.quantity) >= 0
            ? params.row.BidPrice
            : params.value;

         const displayPriceColor=  parseInt(params.row.quantity) > 0 ? params.row.BidColor : params.row.AskColor; 
        return (
          <MDTypography
          color={displayPriceColor}
            sx={{ paddingLeft: "20px", paddingY: "5px", fontSize: "16px" }}
          >
            {formatAndMultiplyValueAssetItem(displayPrice, params.row.instrumentType)}
          </MDTypography>
        );
      },
    },
    {
      field: "changevalue",
      headerName: <GridHeader text="P&L" nextText="P&L(%)" />,
      width: isMedium ? 150 : undefined,
      flex: isMedium ? undefined : 1,
      sortable: false,
      filterable: false,
      renderCell: (params) => {
        const formattedChange = Math.abs(parseFloat(params.value));
        const percentageClassName =
          params.row.percentage_change < 0 ? "negative" : "";
        return (
          <MDBox
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "100%",
              paddingLeft: "20px",
            }}
          >
            <MDTypography
              sx={{ fontSize: "16px", fontWeight: 500 }}
              color={params.value >= 0 ? "success" : "error"}
            >
              {formatAndMultiplyValueAssetItem(formattedChange, params.row.instrumentType)}
            </MDTypography>
            <MDTypography
              color={percentageClassName === "negative" ? "error" : "success"}
              sx={{
                fontSize: "13px",
                fontWeight: 500,
              }}
            >
              ({Math.abs(parseFloat(params.row.percentage_change)).toFixed(2)}
              %)
            </MDTypography>
          </MDBox>
        );
      },
    },
  ];


  function customNoRows() {
    return (
      <MDBox
        height={"100%"}
        width={"100%"}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <MDTypography sx={{ fontSize: isMedium ? "15px" : "20px" }} data-testid="no-rows-message">
          You currently have no stock holdings!
        </MDTypography>
      </MDBox>
    );
  }

  const handleChange = (event, newPage) => {
    setPage(newPage);
  };

  const row = list.map((item, index) => ({
    id: index,
    instrumentName: item?.name2,
    value: item?.value,
    quantity: item?.quantity,
    instrumentId: item?.instrumentId,
    instrumentType: item?.instrumentType,
    Symbol: item?.TradingSymbol,
    avgvalue: item?.avgvalue,
    percentage_change: item?.percentage_change,
    marketvalue: item?.marketValue,
    changevalue: item?.changeValue,
    lastprice: item?.lastPrice,
    LastPrice: item?.LastPrice,
    PreviousPrice: item?.PreviousPrice,
    AskPrice: item?.askPrice,
    BidPrice: item?.bidPrice,
    AskColor:item?.askColor,
    BidColor:item?.bidColor
  }));

  const handleClick = (row) => {
    // console.log(row);
    navigate("/charts", {
      state: {
        instrumentId: row?.instrumentId,
        instrumentType: row?.instrumentType,
        TradingSymbol: row?.Symbol,
        quantity: row?.quantity,
      },
    });
  };

  return (
    <>
      <Card className="card">
        <MDBox sx={{ width: "100%", cursor: "pointer", overflow: "auto" }}>
          <DataGrid
            disableColumnMenu
            rows={row}
            columns={cardItemColumns}
            hideFooter={true}
            rowHeight={55}
            columnHeaderHeight={60}
            slots={{
              noRowsOverlay: customNoRows,
            }}
            disableSelectionOnClick
            loading={loading}
            hideFooterPagination
            disableColumnResize
            autoHeight
            onRowClick={(params) => handleClick(params.row)}
            data-testid="data-grid-item"
            sx={{
              display: "flex",
              cursor: "pointer",
              "& .MuiDataGrid-cell:focus": {
                outline: "none", // Removes the outline on focus
              },
              "& .MuiDataGrid-cell:focus-within": {
                outline: "none", // Covers cases where the cell might have inner focusable elements
              },
              "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within": {
                outline: "none", // Removes the outline on focus for column headers
                pointerEvents: 'none',
              },
            }}
          />
        </MDBox>
        <MDBox display={"flex"} justifyContent={"flex-end"} marginY={"20px"}>
          <Pagination
            count={Math.ceil(rowCount / 10)}
            page={page}
            onChange={handleChange}
            sx={{
              "& .MuiPaginationItem-root": {
                color: darkMode ? white.main : black.main,
                "& .MuiSvgIcon-root": {
                  color: darkMode ? white.main : black.main,
                },
                "&.Mui-selected": {
                  color: darkMode ? white.main : black.main,
                  bgcolor: "rgba(0, 0, 0, 0.12)",
                },
              },
            }}
          />
        </MDBox>
      </Card>
    </>
  );
};

export default CardItemsNasdaq;
