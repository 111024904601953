
export const cryptoFormat = (number) => {
    // Convert the number to a string
    // console.log(number)
    let str = number?.toString();

    // Check if the number is an integer or effectively an integer
    if (Number.isInteger(parseFloat(str)) || parseFloat(str) === Math.floor(parseFloat(str))) {
      return `${parseFloat(str).toFixed(2)}`;
    }

    return parseFloat(number).toFixed(6).replace(/(\.\d*?[1-9])0+$/, '$1');
   
  };
  // .replace(/\B(?=(\d{3})+(?!\d))/g, ",")