export const tradingNotes = [
    {id: '1', label: "Trading :- All symbols will be traded in Lots. you should check the Lot Size (Contract size) of the property/specification of the symbols before trading."},
    {id: '2', label: "Trading Options :- Market, Pending, and Stoploss."},
    {id: '3', label: "New Trade :- For any new trade you should have an adequate free margin. "},
    {id: '4', label: "No Enough Money :- Free margin is not enough to trade, please request your manager to add money into your ID."},
    {id: '5', label: "Close Only :- You can only Square-Off Position in that symbol. (maybe Symbol is Ban For new trade) If Buy/Sell is Hide Please Check:- 1, Volume 2, Price 3, adequate Free Money 4, Securities in Ban 5, Trading session close 6, Disabled by your manager."},
    {id: '6', label: "Volume Limit Reached :- Can not increase more position in the symbol set by your manager. Off Code/Reject :-*Price is not changing or Check your internet connection *or contact us to refresh that symbol & System will not accept pending orders during 1 minute of opening the market. (only market trade)"},
    {id: '7', label: "Done :- Your request is accepted by the server."},
    {id: '8', label: "Pendings :- You can check your Pending Positions & Pending Order in Trade/Summary."},
    {id: '9', label: "Trade History :- You can check your trades in Deals History"},
    {id: '10', label: "Symbol Limit :- Please select only 15-20 symbols on your dashboard for the faster response of the UPTP App."},
]

export const termsConditions = [
    {id:'1', label:'1. All Request (Market, Limit, and Stop Loss) will be on the seller/buyer, Exchange High Low and LTP will not be considered for trading purposes. '},
    {id:'2', label:'2. Jobbing, and Arbitrage are strictly prohibited in our platform. '},
    {id:'3', label:'3. Short time trading, Cheating, Line, Fatka, Chamka, and Chakri trades are strictly prohibited in our platform, if we found any suspicious or fraudulent trade Profit in any account, same will Reverse and Trade Brokrej would also apply. '},
    {id:'4', label:'4. If we find any arbitrage position between Nifty and SGX Nifty in any account, we remove profit trade only. '},
    {id:'5', label:'5. Pending order/Stop-Loss, are not allowed without a position. The order out of circuit price is not valid. '},
    {id:'6', label:'6. Any position that goes to the last time of the Expiry day will be settled by the ask/bid. '},
    {id:'7', label:'7. If any Company declares a dividend it will affect your open position of future and option, i.e. debit or credit in the account.'},
    {id:'8', label:'8. Due to insufficient funds, Once the position will be squared off by the system, will not be returned. '},
    {id:'9', label:'9. The client should maintain an adequate amount at the end of every trading session to carry forward positions, after trading hours may be some positions will Squared-Off by the system, due to the low Equity amount. The manager or his staff is not responsible for informing the balance going low to any client. '},
    {id:'10', label:'10. In intraday position Fund goes below 10% Of margin used in your position will automatically squared off according to margin and current market rate. '},
    {id:'11', label:'11. In SGX,COMEX & FOREX all trade HIGH,LOW,BID,SL will clear according to Our Sever. Buyer/Seller Price.  No other applications rates are considered. '},
    {id:'12', label:'12. If the price is not changing due to a technical issue, then immediately send a WhatsApp message and price screenshot so that we can resolve that matter on Whatsapp message. '},
    {id:'13', label:'13. If a client does not use their ID for 2-3 weeks, that ID may be removed by the server'},
    {id:'14', label:'14. All the rights are reserved with us to Square-Off position or Cancel order.'},
]

export const contactInfo =[
    {id:'1', label:'Technical & For accounts query :- +447452292628 , +447452292657 , +447452292633 (only whatsapp) Business days 8:30 am to 23:55 pm, Saturday 09:00 am to 2:00 pm'},
]