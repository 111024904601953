import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import { Slider} from "@mui/material";
import { formatAndMultiplyValueAssetItem } from "utils/formatAndMultiplyValue";


// Data

function highlowvalues({
  highValue,
  LowValue,
  Open,
  CloseValue,
  price,
  instrumentType,
  askValue,
  askSize,
  bidValue,
  bidSize,
  loading,
  socketHigh,
  socketLow,
  socketOpen,
  socketClose
}) {

  // console.log('high value',highValue)
  // console.log('low value',LowValue)
  // console.log('socket High value',socketHigh)
  // console.log('socket Low value',socketLow)
  // console.log('open value',Open)
  // console.log('close value',CloseValue)
  // console.log('socket close value',socketClose)
  // console.log('socket open value',socketOpen)
  return (
    <Grid
      container
      item
      lg={12}
      xs={12}
      md={12}
      sm={12}
      xl={12}
      spacing={2}
      sx={{
        flexDirection: "row",
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap",
      }}
    >
      <Grid item xs={12} lg={5.9} sm={5.9} xl={3.8} md={5.9}>
        <Card sx={{ height: "180px" }}>
          <Grid ml={3} mr={3} my={"auto"}>
            <Slider
              disabled
              track={false}
              min={instrumentType === "CRYPTO" || instrumentType==="NASDAQ" ? socketLow : LowValue}
              max={instrumentType === "CRYPTO" || instrumentType==="NASDAQ" ? socketHigh : highValue}
              value={price}
              sx={{
                "& .MuiSlider-thumb": {
                  width: 12,
                  height: 12,
                },
                "& .MuiSlider-rail": {
                  opacity: 1,
                  height: 6,
                  backgroundImage: "linear-gradient(195deg, #49a3f1, #1A73E8)",
                  borderRadius: 13,
                },
                "& .MuiSlider-track": {
                  borderRadius: 13,
                },
              }}
            />

            <MDBox sx={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <MDTypography sx={{ fontSize: 11 }}>Today's Low:</MDTypography>
                <MDTypography>
                  {instrumentType === "CRYPTO" || instrumentType==="NASDAQ" ? formatAndMultiplyValueAssetItem(socketLow,instrumentType) : formatAndMultiplyValueAssetItem(LowValue,instrumentType)}
                </MDTypography>
              </div>
              <div>
                <MDTypography sx={{ fontSize: 11 }}>Today's High:</MDTypography>
                <MDTypography>
                  {instrumentType === "CRYPTO" || instrumentType==="NASDAQ" ? formatAndMultiplyValueAssetItem(socketHigh, instrumentType) : formatAndMultiplyValueAssetItem(highValue, instrumentType)}
                </MDTypography>
              </div>
            </MDBox>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} lg={5.9} sm={5.9} xl={3.8} md={5.9}>
        <Card
          sx={{
            height: "180px",
            display: "flex",
            flexDirection: "column",
            paddingTop: "20px",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingX: 4,
              paddingTop: 3,
            }}
          >
            <MDTypography>Open</MDTypography>
            <MDTypography>
              {instrumentType === "CRYPTO" || instrumentType==="NASDAQ" ? formatAndMultiplyValueAssetItem(socketOpen,instrumentType) : formatAndMultiplyValueAssetItem(Open, instrumentType)}
            </MDTypography>
          </Grid>

          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingX: 4,
              paddingTop: 3,
            }}
          >
            <MDTypography>Close</MDTypography>
            <MDTypography>
              {instrumentType === "CRYPTO" || instrumentType==="NASDAQ" ? formatAndMultiplyValueAssetItem(socketClose, instrumentType) : formatAndMultiplyValueAssetItem(CloseValue, instrumentType)}
            </MDTypography>
          </Grid>
        </Card>
      </Grid>
      <Grid item xs={12} lg={5.9} sm={5.9} xl={3.9} md={5.9}>
        <Card sx={{ height: "180px", padding: "10px" }}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              paddingX: "13px",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                marginY: "2px",
              }}
            >
              <MDTypography>Ask Price: </MDTypography>
              <MDTypography>
                {formatAndMultiplyValueAssetItem(askValue, instrumentType)}
              </MDTypography>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography>Bid Price: </MDTypography>
              <MDTypography>
                {formatAndMultiplyValueAssetItem(bidValue, instrumentType)}
              </MDTypography>
            </Grid>

            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography>Ask Size: </MDTypography>
              <MDTypography>{askSize !== "None" ? askSize : "N/A"}</MDTypography>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <MDTypography>Bid Size: </MDTypography>
              <MDTypography>{bidSize !== "None" ? bidSize : "N/A"}</MDTypography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  );
}

export default highlowvalues;
