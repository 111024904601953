import React, { useState, useEffect, useContext } from "react";
import { Grid, Modal, Card } from "@mui/material";
import {
  updateNameApi,
  fetchUserProfile,
} from "../../../../services/profileapis";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import showToast from "utils/toastUtils";
const Changename = ({ openModal, handleCloseModal, setApiData1 }) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");

  const handleFirstnameChange = (e) => {
    const value = e.target.value;
    if (/^[A-Za-z]*$/.test(value)) {
      setFirstname(value);
    }
  };

  const handleLastnameChange = (e) => {
    const value = e.target.value;
    if (/^[A-Za-z]*$/.test(value)) {
      setLastname(value);
    }
  };

  const updateName = async () => {
    try {
      if (firstname.trim() === "" || lastname.trim() === "") {
        showToast("Please enter both first name and last name","warning");
        return
      }

      updateNameApi(firstname, lastname)
        .then(() => {
          showToast("Name updated successfully", "success");
          fetchData();
          setFirstname("");
          setLastname("");
          setTimeout(() => {
            handleCloseModal();
          }, 1000);
        })
        .catch((error) => {
          console.error("Failed to update Name", error);
        });
    } catch (error) {
      showToast(error.message);
    }
  };

  const handleClose = () => {
    setFirstname("");
    setLastname("");
    handleCloseModal();
  };

  const fetchData = async () => {
    const result = await fetchUserProfile();
    if (result) {
      setApiData1(result);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Modal
        animationType="slide"
        transparent={true}
        open={openModal}
        onClose={handleCloseModal}
      >
        <MDBox
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card
            sx={{
              borderRadius: "10px",
              paddingX: "30px",
              paddingY: "6px",
            }}
          >
            <DialogTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "20px",
              }}
            >
              Update Name
            </DialogTitle>
            <DialogContent style={{ textAlign: "center" }}>
              <Grid
                mt={1}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <MDBox
                  mb={2}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <MDInput
                    type="text"
                    label="Firstname"
                    variant="standard"
                    sx={{ width: "100%" }}
                    onChange={handleFirstnameChange}
                    value={firstname}
                  />
                </MDBox>
                <MDBox sx={{ display: "flex", justifyContent: "center" }}>
                  <MDInput
                    type="text"
                    label="Lastname"
                    variant="standard"
                    sx={{ width: "100%" }}
                    onChange={handleLastnameChange}
                    value={lastname}
                  />
                </MDBox>
              </Grid>
              <DialogActions
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                <MDBox mt={2} mb={1}>
                  <MDButton
                    data-testid="change-name-cancel"
                    variant="gradient"
                    color="error"
                    onClick={handleClose}
                  >
                    Cancel
                  </MDButton>
                </MDBox>
                <MDBox mt={2} mb={1}>
                  <MDButton
                    data-testid="change-name-update"
                    variant="gradient"
                    color="success"
                    onClick={() => updateName()}
                  >
                    Update
                  </MDButton>
                </MDBox>
              </DialogActions>
            </DialogContent>
          </Card>
        </MDBox>
      </Modal>
      <ToastContainer />
    </>
  );
};

export default Changename;
